import React from 'react';

const Footer = ({ children }) => {
  return (
    <div className="p-3 bg-gray-700 bottom-0 mt-auto">
      <p className="text-white">Thanks for checking out this preview</p>
    </div>
  );
};

export default Footer;
