import React from 'react';
import Footer from './footer.component';
import Header from './header.component';

const Layout = ({ children }) => {
  return (
    <main className="bg-gray-200 min-h-screen flex-col flex">
      <Header></Header>
      {children}
      <Footer></Footer>
    </main>
  );
};

export default Layout;
