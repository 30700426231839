import React from 'react';

export default function Carousel(props) {
  const { images } = props.indexPage;
  return (
    <div className="bg-black w-100">
      <div className="relative ml-auto mr-auto lg:max-w-screen-lg">
        {!images ? <></> : <img alt="church" src={images[0]} />}
        <h1 className="absolute top-8 left-8 text-white text-7xl max-w-xs">{props.coverText}</h1>
      </div>
    </div>
  );
}
