import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import Icon from '../images/icon.svg';

const Header = (props) => {
  const [menuExpanded, setMenuExpanded] = useState(false);

  const headerLinks = [
    { title: 'Events', href: '/events', id: 1 },
    { title: 'Worship', href: '/worship', id: 2 },
    { title: 'About', href: '/about', id: 3 },
  ];

  const { dataJson } = useStaticQuery(graphql`
    query HeaderQuery {
      dataJson {
        churchName
      }
    }
  `);

  function toggleMenuExpanded() {
    setMenuExpanded(!menuExpanded);
  }

  return (
    <nav
      className={
        'flex items-center justify-between flex-wrap bg-blue-500 shadow-md sticky top-0 z-10'
      }
    >
      <Link to="/" className={'flex items-center flex-shrink-0 text-white m-5'}>
        <Icon className={'fill-white h-8 w-8 mr-2'} width="54" height="54" />
        <span className={'font-semibold text-xl tracking-tight'}>{dataJson.churchName}</span>
      </Link>
      {headerLinks.map((link) => {
        return (
          <Link
            className="h-full p-6 hover:bg-blue-600 hidden lg:block transition-all"
            to={link.href}
            key={link.id}
          >
            <div className="text-lg h-full text-blue-100 hover:text-white">{link.title}</div>
          </Link>
        );
      })}
      <div className={'block lg:hidden mr-6'}>
        <button
          className={
            'flex items-center px-3 py-2 border rounded text-blue-200 border-blue-400 hover:text-white hover:border-white'
          }
          onClick={toggleMenuExpanded}
        >
          <svg
            className={'fill-current h-3 w-3'}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div
        className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto overflow-hidden ${
          !menuExpanded ? 'max-h-0' : 'pl-6 pb-6'
        } transition-all duration-200`}
      >
        <div className={'text-sm lg:flex-grow'}>
          {headerLinks.map((link) => {
            return (
              <Link
                to={link.href}
                className={
                  'block pt-4 lg:inline-block text-blue-200 hover:text-white pr-4 transition-all'
                }
                key={link.id}
              >
                {link.title}
              </Link>
            );
          })}
        </div>
      </div>
    </nav>
  );
};

export default Header;
